import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { memo } from 'react'
import { SvgLoader, SvgProxy } from 'react-svgmt'

import { FIVE_MINUTES_AGO } from '@consts/DateConsts'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { Colors } from '@pure/assets/Colors'

import {
  Bergshamra8007,
  EightThousand,
  EightThousandFour,
  Honekullavagen8001,
  ImportGatan7983,
  KungalvBostader7989,
  NivikaJkpg8003,
  Raberget7999,
  Redegatan8006,
  SevenNineEightEight,
  SevenNineNineEight,
  SevenNineNineFive,
  SevenNineNineOne,
  SevenNineNineTwo,
  SevenNineNineZero
} from '@my-drifter/assets/siteSvg/SiteSvgs'
import FullScreenSpinner from '@my-drifter/components/FullScreenSpinner'
import Layout from '@my-drifter/components/Layout'
import useAppState from '@my-drifter/hooks/useAppState'
import { useGetActiveSiteCameraEvents } from '@my-drifter/hooks/useGetActiveSiteCameraEvents'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useUser } from '@my-drifter/hooks/useUser'

import { Spacings } from '@web-components/enums/Spacings'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const DashboardScreen = () => {
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)
  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const siteSVGs = {
    '7983': ImportGatan7983,
    '7988': SevenNineEightEight,
    '7989': KungalvBostader7989,
    '7990': SevenNineNineZero,
    '7991': SevenNineNineOne,
    '7992': SevenNineNineTwo,
    '7995': SevenNineNineFive,
    '7998': SevenNineNineEight,
    '7999': Raberget7999,
    '8000': EightThousand,
    '8001': Honekullavagen8001,
    '8003': NivikaJkpg8003,
    '8004': EightThousandFour,
    '8006': Redegatan8006,
    '8007': Bergshamra8007,
    '8009': Redegatan8006, // Redegatan is split into two sites
    '8010': NivikaJkpg8003 // Nivika Jönköping is split into two sites
  }

  const firstSiteSvg = user?.siteIds ? user.siteIds[0] : Object.keys(siteSVGs)[0]

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  const { data: activeSiteCameraEvents, isLoading: isLoadingActiveSiteCameraEvents } = useGetActiveSiteCameraEvents(
    selectedSiteId ? selectedSiteId : firstSiteSvg
  )

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  return !isLoadingUser ? (
    <Layout
      isDashboardScreen
      siteSVGs={siteSVGs}
      selectedSiteId={selectedSiteId ? selectedSiteId : firstSiteSvg}
      setSelectedSiteId={setSelectedSiteId}
    >
      <StyledSvgLoader path={siteSVGs[selectedSiteId ? selectedSiteId : firstSiteSvg]}>
        {activeSiteCameraEvents &&
          !isLoadingActiveSiteCameraEvents &&
          Object.keys(activeSiteCameraEvents).map((siteCameraEvent) => {
            const { place_code, record_time } = activeSiteCameraEvents[siteCameraEvent]

            // record_time is stored in a unix timestamp, so we need to convert it to a dayjs object
            const recordTime = dayjs.unix(record_time.seconds)

            if (recordTime.isAfter(FIVE_MINUTES_AGO)) {
              return (
                <SvgProxy key={place_code} selector={`#${place_code}`} fill={Colors.white}>
                  {'PARKED'}
                </SvgProxy>
              )
            }
          })}
      </StyledSvgLoader>
    </Layout>
  ) : (
    <FullScreenSpinner />
  )
}

const StyledSvgLoader = styled(SvgLoader)`
  width: 95%;
  height: 95%;
  align-self: center;
  margin-bottom: ${Spacings.xxxl};
`

export default memo(DashboardScreen)
