import { signOut } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

import { auth } from '@my-drifter/libs/FirebaseOptions'

import useAppState, { DEFAULT_STATE } from './useAppState'

export function useLogout(onLogout?: () => void) {
  const { setState } = useAppState()
  const navigate = useNavigate()

  return () =>
    signOut(auth).finally(() => {
      onLogout && onLogout()
      setState(DEFAULT_STATE)
      localStorage.clear()
      navigate('/')
    })
}
