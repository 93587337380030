import { Skeleton } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

import formatMonth from '@pure/libs/formatMonth'

import PortalTexts from '@my-drifter/assets/texts_extended.json'
import { useLanguage } from '@my-drifter/hooks/useLanguage'

import DateTable from './DateTable'
import FigmaText from './FigmaText'

export interface RevenueData {
  month: string
  date: dayjs.Dayjs
  generated: number
  paid: number
  outstanding: number
}

interface RevenueTableProps {
  data: RevenueData[]
  loading: boolean
}

function RevenueTable({ data, loading }: RevenueTableProps) {
  const localeString = useLanguage()
  const columns = React.useMemo(
    () => [
      {
        Header: <FigmaText textKey={PortalTexts.revenueReportrevenueBreakdownmonthColumnTitle} />,
        id: 'date',
        accessor: (row) => formatMonth(row.date, localeString),
        sortType: (rowA, rowB) => {
          const dateA = rowA.original.date
          const dateB = rowB.original.date
          return Math.floor(dateA.unix()) - Math.floor(dateB.unix())
        },
        ...(loading && { Cell: <Skeleton /> })
      },
      {
        Header: <FigmaText textKey={PortalTexts.revenueReportrevenueBreakdowngeneratedColumnTitle} />,
        id: 'generated',
        accessor: (row) => Math.round(row.generated).toLocaleString(localeString),
        ...(loading && { Cell: <Skeleton /> })
      },
      {
        Header: <FigmaText textKey={PortalTexts.revenueReportrevenueBreakdownpaidColumnTitle} />,
        id: 'paid',
        accessor: (row) => Math.round(row.paid).toLocaleString(localeString),
        ...(loading && { Cell: <Skeleton /> })
      },
      {
        Header: <FigmaText textKey={PortalTexts.revenueReportrevenueBreakdownoutstandingColumnTitle} />,
        id: 'outstanding',
        accessor: (row) => Math.round(row.outstanding).toLocaleString(localeString),
        ...(loading && { Cell: <Skeleton /> })
      }
    ],
    [localeString, loading]
  )

  const tableData = React.useMemo(() => (loading ? Array(12).fill({}) : data), [loading, data])

  return <DateTable<RevenueData> columns={columns} data={tableData} />
}

export default RevenueTable
