import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { memo } from 'react'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { UserPermitConnection, UserPermitConnectionSchemaStatus } from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'
import { formatText } from '@pure/libs/TextRepositoryHelper'

import { useUser } from '@my-drifter/hooks/useUser'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

type PermitsUserBoxProps = {
  userPermitConnection: UserPermitConnection
  setIsDeleteUserDrawerOpen: (isOpen: boolean) => void
  setUserPermitConnectionToDelete: (userPermitConnection?: UserPermitConnection) => void
}

const PermitsUserBox = ({
  userPermitConnection,
  setIsDeleteUserDrawerOpen,
  setUserPermitConnectionToDelete
}: PermitsUserBoxProps) => {
  const { data: user } = useUser(userPermitConnection?.userId)

  return user?.phone || (user?.firstName && user?.lastName) ? (
    <Container
      fullWidth
      fullPadding
      direction="row"
      isTerminated={userPermitConnection?.status === UserPermitConnectionSchemaStatus.TERMINATED}
      spacing={Spacings.xs}
      onClick={() => {
        setIsDeleteUserDrawerOpen(true)
        setUserPermitConnectionToDelete(userPermitConnection)
      }}
    >
      <Box fullWidth>
        <Box direction="row" gap={Spacings.xxs} bottom spacing={Spacings.xs}>
          {user?.name && (!user?.firstName || !user?.lastName) ? (
            <FigmaText
              textKey={Texts.textMyPortalUserNameInListCss}
              text={user?.name !== 'undefined undefined' && user?.name !== 'null null' ? `${user?.name} - ` : ''}
            />
          ) : (
            <FigmaText
              textKey={Texts.textMyPortalUserNameInListCss}
              text={
                `${user?.firstName} ${user?.lastName}` !== 'undefined undefined' ||
                `${user?.firstName} ${user?.lastName}` !== 'null null'
                  ? `${user?.firstName} ${user?.lastName} - `
                  : ''
              }
            />
          )}

          <FigmaText textKey={Texts.textMyPortalUserNameInListCss} text={String(user?.id)} />
        </Box>
        {user?.phone && <FigmaText textKey={Texts.textMyPortalUserPhoneInListCss} text={user?.phone} />}
      </Box>
      <Box fullWidth align="flex-end" justify="center" gap={Spacings.xxs}>
        {userPermitConnection?.status === UserPermitConnectionSchemaStatus.ACTIVE && (
          <FigmaText textKey={Texts.textMyPortalUserConnectionStatusActive} />
        )}
        {userPermitConnection?.status === UserPermitConnectionSchemaStatus.TERMINATED && (
          <FigmaText textKey={Texts.textMyPortalUserConnectionStatusTerminated} />
        )}
        {userPermitConnection?.status === UserPermitConnectionSchemaStatus.TERMINATED &&
          userPermitConnection?.terminatedAt && (
            <FigmaText
              textKey={Texts.textMyPortalUserPhoneInListCss}
              text={formatText(
                getFigmaText(Texts.textMyPortalUserConnectionStatusTerminatedAt),
                [dayjs(userPermitConnection.terminatedAt).format('YYYY-MM-DD')],
                {
                  splitCharacter: 'xxxx-xx-xx'
                }
              )}
            />
          )}
      </Box>
    </Container>
  ) : null
}

const Container = styled(Box)<{ isTerminated: boolean }>`
  cursor: pointer;
  border: 1px solid #f3f3f3;
  margin-bottom: ${Spacings.xs};
  border-radius: ${BorderRadiusesPx.minimum};
  background-color: ${({ isTerminated }) => (isTerminated ? Colors.red1 : Colors.white)};

  :hover {
    border-color: ${Colors.primary};
    transition: border-color 0.2s ease-in-out;
  }
`

export default memo(PermitsUserBox)
