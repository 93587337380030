import { en, LANGUAGES } from '@consts/Locale'

import { Language } from '@pure/emu/Antiloop'

import { StorageKey } from './useLocalStorageItemHelper'

export const getLanguageCode = (): Language => {
  const json = window.localStorage.getItem(StorageKey.STATE)
  return getDefaultLanguageCode()
}

export function getDefaultLanguageCode(): Language {
  const defaultLanguageCode = navigator.language?.substr(0, 2) as Language | undefined
  if (!defaultLanguageCode) return en
  if (LANGUAGES.includes(defaultLanguageCode)) return defaultLanguageCode
  return en
}

export const useLanguage = getLanguageCode
