import 'react-toastify/dist/ReactToastify.css'
import './libs/FirebaseOptions'
import './libs/Fonts'

import { ThemeProvider } from '@mui/material'
import { ComponentType } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { PermitsProvider } from '@my-drifter/store/PermitsProvider'

import ErrorBoundaryProvider from './components/ErrorBoundaryProvider'
import { Context, useAppStateContext } from './hooks/useAppState'
import { FirebaseAuthUserProvider } from './hooks/useFirebaseAuthUser'
import { SelectedSiteProvider } from './hooks/useSelectedSiteContext'
import { MaterialTheme } from './libs/MaterialTheme'
import DashboardScreen from './screens/DashboardScreen'
import PermitsScreen from './screens/PermitsScreen'
import RevenueScreen from './screens/RevenueScreen'
import SessionsScreen from './screens/SessionsScreen'
import SignInScreen from './screens/SignInScreen'
import StaticQRMakerScreen from './screens/StaticQRMakerScreen'
import { AuthProvider } from './store/AuthProvider'

function App() {
  const context = useAppStateContext()
  const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } })

  return (
    <ErrorBoundaryProvider>
      <ThemeProvider theme={MaterialTheme}>
        <Context.Provider value={context}>
          <SelectedSiteProvider>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <AuthProvider>
                  <FirebaseAuthUserProvider>
                    <Routes>
                      <Route path="/" Component={SignInScreen as ComponentType<object> | null | undefined} />
                      <Route path="/dashboard" Component={DashboardScreen} />
                      <Route path="/sessions" Component={SessionsScreen} />
                      <Route path="/revenue" Component={RevenueScreen} />
                      <Route
                        path="/permits"
                        Component={() => (
                          <PermitsProvider>
                            <PermitsScreen />
                          </PermitsProvider>
                        )}
                      />
                      <Route path="/static-qr-maker" Component={StaticQRMakerScreen} />
                    </Routes>
                  </FirebaseAuthUserProvider>
                </AuthProvider>
                <ToastContainer />
              </BrowserRouter>
            </QueryClientProvider>
          </SelectedSiteProvider>
        </Context.Provider>
      </ThemeProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
