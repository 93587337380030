import { createContext } from 'react'

import usePermitsScreenData from '@my-drifter/hooks/usePermitsScreenData'

export const PermitsContext = createContext({} as ReturnType<typeof usePermitsScreenData>)

export function PermitsProvider({ children }) {
  const permitsScreenData = usePermitsScreenData()

  return <PermitsContext.Provider value={permitsScreenData}>{children}</PermitsContext.Provider>
}
