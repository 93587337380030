import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import * as yup from 'yup'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Permit, UserPermitConnection, UserPermitConnectionSchemaStatus } from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'

import { formatPayerAlias } from '@web-js/libs/PhoneNumberHelper'

import { getOrCreateUserSecure } from '@my-drifter/libs/CloudFunctionsApiHandler'
import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import RightSideDrawer from './RightSideDrawer'
import TextFieldForm from './TextFieldForm'

interface RightSideVehiclesDrawerProps {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}

const AddUserDrawer = ({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  setIsDrawerOpen
}: RightSideVehiclesDrawerProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isUserAlreadyAdded, setIsUserAlreadyAdded] = useState(false)

  const schema = yup.object().shape({
    name: yup.string(),
    phone: yup.string().required(Texts.textMyPortalPhoneValidation)
  })

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: { name?: string; phone: string }) => {
    setIsLoading(true)
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return
    const formattedPhone = formatPayerAlias(data.phone)

    const user = await getOrCreateUserSecure({ phone: `+${formattedPhone}`, name: data.name })

    if (!user) return setIsLoading(false)

    const userAlreadyExists = permit.userPermitConnections?.find(
      (connection) => String(connection.userId) === String(user.id)
    )

    if (userAlreadyExists) {
      setIsUserAlreadyAdded(true)
      return setIsLoading(false)
    }

    const updatedPermit = {
      ...permit,
      userPermitConnections: [
        ...(permit.userPermitConnections as UserPermitConnection[]),
        {
          acceptedAt: dayjs().format(),
          createdAt: dayjs().format(),
          status: UserPermitConnectionSchemaStatus.ACTIVE,
          updatedAt: dayjs().format(),
          updatedBy: 'MyDrifter',
          userId: user?.id?.toString()
        } as UserPermitConnection
      ] as UserPermitConnection[]
    }

    upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)

    setIsDrawerOpen(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      reset({ name: '', phone: '' })
      setIsUserAlreadyAdded(false)
    }
  }, [isDrawerOpen])

  return (
    <RightSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}>
      <FigmaText textKey={Texts.textMyPortalAddUserHeader} />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box fullWidth gap={Spacings.xs}>
          <FigmaText textKey={Texts.textGuestInputStaffName} />
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <TextFieldForm
                {...field}
                value={field.value}
                fullWidth
                fieldState={fieldState}
                autoFocus
                placeholder="John Doe"
              />
            )}
          />
        </Box>
        <Box fullWidth gap={Spacings.xs}>
          <FigmaText textKey={Texts.textMyPortalAddUserPhoneInput} />
          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState }) => (
              <TextFieldForm
                {...field}
                value={field.value}
                fullWidth
                fieldState={fieldState}
                autoFocus
                placeholder="+46..."
              />
            )}
          />
        </Box>
        <Button fullWidth type="submit" loading={isLoading} textKey={Texts.textButtonsAddButton} />
      </StyledForm>
      {isUserAlreadyAdded && (
        <ErrorBox fullWidth fullPadding>
          <FigmaText textKey={Texts.textMyPortalErrorUserAlreadyAdded} />
        </ErrorBox>
      )}
    </RightSideDrawer>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  margin-top: ${Spacings.m};
`

const ErrorBox = styled(Box)`
  margin-top: ${Spacings.s};
  border: 1px solid ${Colors.yellow1};
  background-color: ${Colors.red1};
  border-radius: ${BorderRadiusesPx.minimum};
`

export default memo(AddUserDrawer)
