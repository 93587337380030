import dayjs from 'dayjs'

import { RevenueSessionData } from '@contracts/types/GetSessionDataForSiteRequest'

const CUSTOMER_EARNINGS_PERCENTAGE = 0.7
export const CUSTOMER_PERMITS_PERCENTAGE = 0.9

/* 
  This function is used to calculate the Customer Revenue
  which is 70% of the total revenue, excluding Administration Fees
*/
export function groupAndAggregatePaymentsByMonth(data: RevenueSessionData[]) {
  return data.reduce<
    Record<string, { month: string; date: dayjs.Dayjs; generated: number; paid: number; outstanding: number }>
  >((acc, session) => {
    const sessionDate = dayjs(session.date)
    const date = sessionDate.startOf('month')
    const month = date.format('MMMM YYYY')

    if (!acc[month]) {
      acc[month] = {
        month,
        date,
        generated: 0,
        paid: 0,
        outstanding: 0
      }
    }

    acc[month].generated += CUSTOMER_EARNINGS_PERCENTAGE * session.sumCost
    if (session.paymentStatus === 'paid') {
      acc[month].paid += CUSTOMER_EARNINGS_PERCENTAGE * session.sumCost
    } else if (session.paymentStatus === 'pending') {
      acc[month].outstanding += CUSTOMER_EARNINGS_PERCENTAGE * session.sumCost
    }

    return acc
  }, {})
}
