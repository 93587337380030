import { useMediaQuery } from '@mui/material'

import useDeviceDetect from './useDeviceDetect'

const DESKTOP_MIN_WIDTH = '600px'
const XLARGE_MIN_WIDTH = '1600px'

export default function useIsMobile(minWidth: string = DESKTOP_MIN_WIDTH) {
  const { isMobile } = useDeviceDetect()
  const hasMobileWidth = !useMediaQuery(`screen and (min-width: ${minWidth})`)
  if (!isMobile) return false
  return isMobile || hasMobileWidth
}

export function useIsXLDesktop(minWidth: string = XLARGE_MIN_WIDTH) {
  const hasXLWidth = useMediaQuery(`screen and (min-width: ${minWidth})`)
  return !useIsMobile() && hasXLWidth
}

export const useIsDesktop = () => !useIsMobile()
