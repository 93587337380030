import { memo } from 'react'

import { useSite } from '@my-drifter/hooks/useSite'
import Texts from '@my-drifter/libs/Texts'

import FigmaText from './FigmaText'

type DropdownSelectedItemProps = {
  selected?: string
  options?: {
    label?: string
    value?: string
  }[]
  dropdownType: 'site' | 'siteDebtor' | 'permit' | 'month'
}

const DropdownSelectedItem = ({ selected, options, dropdownType }: DropdownSelectedItemProps) => {
  const { data: site } = useSite(selected)

  const getDropdownEmptyTextKey = () => {
    switch (dropdownType) {
      case 'site':
        return Texts.textMyPortalEmptyStateSite
      case 'siteDebtor':
        return Texts.textMyPortalEmptyStateDebtorSelector
      case 'permit':
        return Texts.textMyPortalEmptyStatePermit
      case 'month':
        return Texts.textMyPortalStatsStatisticsColumnDate
    }
  }

  return (
    <>
      {!selected && <FigmaText textKey={getDropdownEmptyTextKey()} />}
      {selected && (
        <FigmaText
          textKey={Texts.textPermitManagementPermitCssUserName}
          text={
            selected && dropdownType === 'site'
              ? `${site?.name} (${options?.find((option) => option.value === selected)?.label})`
              : options?.find((option) => option.value === selected)?.label
          }
        />
      )}
    </>
  )
}

export default memo(DropdownSelectedItem)
