import { State } from '@my-drifter/hooks/useAppState'

import { getUserByPhoneNumber } from './DBApiHandler'

export const getUser = async (state: State, setState: (state: State) => unknown) => {
  if (!state?.firebaseUser) return

  const user = await getUserByPhoneNumber(state.firebaseUser.phoneNumber)

  if (!user) return

  setState({ ...state, userId: String(user?.id) })
  localStorage.setItem('userId', String(user?.id))
}
