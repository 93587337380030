import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

export default function ErrorBoundaryView() {
  return (
    <Box fullWidth fullPadding align="center" spacing={Spacings.xxl} fullHeight>
      <Box>{/* <FigmaImageContainer imageKey={Images.receiptDrifterLogo} /> */}</Box>
      <Box top>
        <FigmaText textKey={TextKeys.errorMaintenanceTitle} />
      </Box>
      <Box top>
        <FigmaText textKey={TextKeys.errorMaintenanceDescription} />
      </Box>
    </Box>
  )
}

export const TextKeys = {
  errorMaintenanceTitle: {
    name: 'errorMaintenanceTitle',
    characters: '',
    texts: {
      swe: 'Drifter is currently down for maintenance.',
      eng: 'Drifter is currently down for maintenance.'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '36px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  errorMaintenanceDescription: {
    name: 'errorMaintenanceDescription',
    characters: '',
    texts: {
      swe: 'We expect to be back shortly. Thanks for your patience.',
      eng: 'We expect to be back shortly. Thanks for your patience.'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(145, 143, 153, 1)'
    },
    pageName: 'Payment Flows QR'
  }
}
