export enum StorageKey {
  STATE = 'STATE',
  SIGN_IN_STATE = 'SIGN_IN_STATE',
  SESSION = 'SESSION'
}

export enum StorageType {
  LOCAL_STORAGE,
  SESSION_STORAGE,
  MEMORY_STORAGE
}

export const isValidJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
export type Options = {
  ignoredKeys?: string[]
  storageType?: StorageType
}

export const DEFAULT_OPTIONS = {
  ignoredKeys: [],
  storageType: StorageType.LOCAL_STORAGE
}

export const getElligebleStorage = (storageType?: StorageType): Storage => {
  try {
    let storage: Storage = new MemoryStorage()
    if (storageType === StorageType.LOCAL_STORAGE) storage = window.localStorage
    if (storageType === StorageType.SESSION_STORAGE) storage = window.sessionStorage
    storage.getItem(StorageKey.STATE)
    return storage
  } catch (e) {
    return new MemoryStorage()
  }
}

class MemoryStorage implements Storage {
  private storage: { [key: string]: string }

  constructor() {
    this.storage = {}
  }

  getItem(key: string): string | null {
    return this.storage[key] || null
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value
  }

  removeItem(key: string): void {
    delete this.storage[key]
  }

  clear(): void {
    this.storage = {}
  }

  key(index: number): string | null {
    const keys = Object.keys(this.storage)
    return index >= 0 && index < keys.length ? keys[index] : null
  }

  get length(): number {
    return Object.keys(this.storage).length
  }
}
