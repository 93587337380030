import * as Sentry from '@sentry/react'
import React from 'react'

import { captureException } from '@web-js/libs/SentryHelper'

import config from '@my-drifter/Config'

import ErrorBoundaryView from './ErrorBoundaryView'

const FallBackComponent = config.enableSentry ? ErrorBoundaryView : undefined

const ErrorBoundaryProvider: React.FC<any> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallBackComponent} onError={(err) => captureException(err)}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundaryProvider
