import styled from '@emotion/styled'

import { Colors } from '@pure/assets/Colors'

import ZIndices from '@web-js/enums/Zindices'

import Box from './Box'

const FullScreenSpinner = () => {
  return (
    <Overlay fullWidth position="fixed" align="center" justify="center" zIndex={ZIndices.heaven}>
      <Spinner />
    </Overlay>
  )
}

const Overlay = styled(Box)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
`

const Spinner = styled(Box)`
  width: 110px;
  height: 110px;
  border: 8px solid lightgray;
  border-radius: 50%;
  border-top-color: ${Colors.brandPink};
  animation: spin 1s linear infinite;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export default FullScreenSpinner
