import { memo } from 'react'

import Box from '@my-drifter/components/Box'
import Button from '@my-drifter/components/Button'
import FigmaImageContainer from '@my-drifter/components/FigmaImageContainer'
import FigmaText from '@my-drifter/components/FigmaText'
import { useLogout } from '@my-drifter/hooks/useLogout'
import Images from '@my-drifter/libs/Images'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

const NotAuthorizedScreen: React.FC<{ enableLogoutButton?: boolean }> = () => {
  const logout = useLogout()

  return (
    <Box fullWidth fullHeight align="center" justify="center">
      <Box top spacing={Spacings.xxxl}>
        <FigmaImageContainer imageKey={Images.drifterLogoDark} width={300} />
      </Box>
      <Box top spacing={Spacings.xxxl}>
        <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingInvalidToken} />
      </Box>
      <Box top>
        <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingInvalidTokenContactSupport} />
      </Box>
      <Box width="200px" top spacing={Spacings.xxxl}>
        <Button
          fullWidth
          onClick={logout}
          textKey={Texts.textMenuUserMenuUserMenuLogout}
          verticalSpacing={Spacings.s}
          buttonType="secondary"
        />
      </Box>
    </Box>
  )
}

export default memo(NotAuthorizedScreen)
