const env: string | undefined = (import.meta as any).env?.VITE_ENVIRONMENT

export const defaultConfig = {
  sentryDsn: 'https://0477278e78eceec787dbc523a5436bb5@o4504077826981888.ingest.us.sentry.io/4506669751730176',
  enableSentry: false,
  enableSlack: false
}

export type ConfigType = typeof defaultConfig

export const local: ConfigType = {
  ...defaultConfig,
  enableSentry: false,
  enableSlack: false
}

export const prod: ConfigType = {
  ...defaultConfig,
  enableSentry: true,
  enableSlack: true
}

export const config =
  {
    local,
    prod
  }[env || 'prod'] || prod

export default config
