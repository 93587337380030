import { DatePicker as MUIDatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'

export default function DatePicker(props: DatePickerProps<Dayjs>): JSX.Element {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDatePicker slotProps={{ textField: { size: 'small' } }} {...props} />
    </LocalizationProvider>
  )
}
