import styled from '@emotion/styled'
import DeleteIcon from '@mui/icons-material/Delete'
import { memo, useState } from 'react'
import { createPortal } from 'react-dom'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Permit } from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import FullScreenModal from '@my-drifter/components/FullScreenModal'
import { useUser } from '@my-drifter/hooks/useUser'
import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

type PermitsStaffMemberBoxProps = {
  userId: string
  permit: Permit
  selectedSiteId: string
  selectedSitePermitDebtorId: string
}

const PermitsStaffMemberBox = ({
  userId,
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId
}: PermitsStaffMemberBoxProps) => {
  const { data: user } = useUser(userId)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const modalRoot = document.getElementById('modal-root')

  const onDelete = async () => {
    const updatedPermit = {
      ...permit,
      staffMembersIds: [...(permit.staffMembersIds?.filter((memberId) => memberId.toString() !== userId) as string[])]
    } as Permit

    await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)
  }

  const ConfirmationModal = () =>
    modalRoot
      ? createPortal(
          <FullScreenModal
            isOpen={isDeleteModalOpen}
            titleTextKey={TextsExtended.textNotificationsNotificationVerifyRemovalOfStaffMemberFromPermit}
            leftButtonTextKey={TextsExtended.textButtonsCtaVerifyAndRemoveStaffMember}
            rightButtonTextKey={Texts.textButtonsManageCancel}
            leftButtonOnClick={onDelete}
            rightButtonOnClick={() => setIsDeleteModalOpen(false)}
          />,
          modalRoot
        )
      : null

  return (
    <Container fullWidth fullPadding direction="row" spacing={Spacings.xs}>
      {isDeleteModalOpen && <ConfirmationModal />}
      <Box fullWidth>
        <Box direction="row" gap={Spacings.xxs} bottom spacing={Spacings.xs}>
          <FigmaText
            textKey={Texts.textMyPortalUserNameInListCss}
            text={[user?.firstName, user?.lastName].filter(Boolean).join(' ') || user?.name}
          />
          (<FigmaText textKey={Texts.textMyPortalUserNameInListCss} text={String(user?.id)} />)
        </Box>
        {user?.phone && <FigmaText textKey={Texts.textMyPortalUserPhoneInListCss} text={user?.phone} />}
      </Box>
      <Box fullWidth align="flex-end" justify="center" gap={Spacings.xxs}>
        <StyledDeleteIcon onClick={() => setIsDeleteModalOpen(true)} />
      </Box>
    </Container>
  )
}

const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  :hover {
    color: ${Colors.primary};
  }
`

const Container = styled(Box)`
  border: 1px solid #f3f3f3;
  margin-bottom: ${Spacings.xs};
  border-radius: ${BorderRadiusesPx.minimum};
  :hover {
    border-color: ${Colors.primary};
    transition: border-color 0.2s ease-in-out;
  }
`

export default memo(PermitsStaffMemberBox)
