import {
  CloudFunctions,
  GetOrCreateUserSecureRequest,
  GetSessionColumnDataForSiteRequest,
  PermitRequest
} from '@contracts/types/CloudFunctionTypes'
import {
  GetSessionDataForSiteRequest,
  GetSessionDataForSiteResponse
} from '@contracts/types/GetSessionDataForSiteRequest'
import { HikerUser } from '@contracts/types/HikerUser'
import { Permit } from '@contracts/types/Permit'

import { GetSessionStatsForSiteResponse } from '@functions-my-drifter/types/GetSessionStatsForSite'
import {
  PermitIncomeByMonthRequest,
  PermitIncomeByMonthResponse
} from '@functions-my-drifter/types/PermitIncomeByMonthForSite'

import { callFunction, callFunctionWithAuthorized } from '@web-js/libs/CloudFunctionsApiHandlerHelper'

import { auth, functions } from './FirebaseOptions'

export const getOrCreateUserSecure = (req: GetOrCreateUserSecureRequest): Promise<HikerUser> =>
  callFunction({ functions, cloudfunction: CloudFunctions.getOrCreateUserSecure, data: req }) as Promise<HikerUser>

export const getSessionColumnDataForSite = (
  req: GetSessionColumnDataForSiteRequest
): Promise<GetSessionStatsForSiteResponse> =>
  callFunction({
    functions,
    cloudfunction: CloudFunctions.getSessionColumnDataForSite,
    data: req
  }) as Promise<GetSessionStatsForSiteResponse>

export function getRevenueDataForSite(req: GetSessionDataForSiteRequest): Promise<GetSessionDataForSiteResponse> {
  return callFunction({
    functions,
    cloudfunction: CloudFunctions.getFinancialDataForSite,
    data: req
  }) as Promise<GetSessionDataForSiteResponse>
}

export function getPermitIncomeByMonthForSite(req: PermitIncomeByMonthRequest) {
  return callFunction({
    functions,
    cloudfunction: CloudFunctions.getPermitIncomeByMonthForSite,
    data: req
  }) as Promise<PermitIncomeByMonthResponse>
}

export const getLinkToGuestParkingFeauture = (req: {
  siteId: string
  expiresAt?: string
  key?: string
}): Promise<{ url: string }> =>
  callFunction({
    functions,
    cloudfunction: CloudFunctions.getLinkToGuestParkingFeauture,
    data: req
  }) as Promise<{ url: string }>

export const terminatePermitConnection = (data: PermitRequest): Promise<Permit> =>
  callFunctionWithAuthorized({
    auth: auth,
    functions,
    cloudfunction: CloudFunctions.terminatePermitConnection,
    data
  }) as Promise<Permit>
