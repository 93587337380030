import { z } from 'zod'

import { PermitTemplateBaseSchema } from '@contracts/types/PermitTemplate'
import { SitePricingSchema } from '@contracts/types/Site'

export type Permit = z.infer<typeof PermitSchema>
export type DisabilityPermit = z.infer<typeof DisabilityPermitSchema>

export enum UserPermitConnectionSchemaStatus {
  ACTIVE = 'active',
  INVITED = 'invited',
  TERMINATED = 'terminated'
}

export enum DisabilityPermitStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export type DebtorPermits = { debtorName: string; permits: Permit[] }

export type UserPermitConnection = z.infer<typeof UserPermitConnectionSchema>

export type PermitEvent = z.infer<typeof PermitEventSchema>

export type PermitStatistic = z.infer<typeof PermitStatisticSchema>

const UserPermitConnectionSchema = z.object({
  userId: z.string(),
  expiryDate: z.string().optional(),
  status: z.nativeEnum(UserPermitConnectionSchemaStatus),
  createdAt: z.string(),
  acceptedAt: z.string().optional(),
  updatedAt: z.string(),
  updatedBy: z.string(),
  terminatedAt: z.string().optional(),
  bankIdAutostartToken: z.string().optional()
})

export const PermitEventSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  metadata: z.record(z.any()).optional()
})

export const PermitStatisticSchema = z.object({
  numberOfConcurrentCars: z.number().default(0)
})

export const PermitSchema = PermitTemplateBaseSchema.extend({
  id: z.string(),
  permitDebtorId: z.string(),
  name: z.string(),
  price: z.number(),
  userPermitConnections: z.array(UserPermitConnectionSchema).optional(),
  plateNumbers: z.array(z.string()),
  plateNumbersCurrentlyParked: z.array(z.string()).default([]),
  statistics: z.record(PermitStatisticSchema).default({}),
  events: z.record(PermitEventSchema).default({}),
  code: z.string().optional(), // used when no one has invited user. User has just gotten a code. (Case 2)
  createdFromTemplateId: z.string().optional(),
  includeSlotPlaceCodes: z.array(z.string()).optional(),
  excludeSlotPlaceCodes: z.array(z.string()).optional(),
  segmentIds: z.array(z.string()).optional(),
  pricing: SitePricingSchema.optional(),
  type: z.enum(['guest']).optional(),
  staffMembersIds: z.array(z.string()).optional()
})

export const DisabilityPermitSchema = z.object({
  disabilityPermitUploadedAt: z.string(),
  disabilityPermitFrontUrl: z.string(),
  disabilityPermitBackUrl: z.string(),
  disabilityPermitStatus: z.nativeEnum(DisabilityPermitStatus)
})

export type UpsertPermitRequest = z.infer<typeof UpsertPermitRequestSchema>

export const UpsertPermitRequestStrictSchema = PermitSchema.extend({
  userIds: z.array(z.string()).optional() // this is used by CS to easier set userIds to permits, it is removed by upsertPermit
})

export const UpsertPermitRequestSchema = UpsertPermitRequestStrictSchema.partial()
  .extend({
    permitDebtorId: z.string()
  })
  .passthrough()
