import styled from '@emotion/styled'
import { memo } from 'react'

import { Colors } from '@pure/assets/Colors'

import useAppState from '@my-drifter/hooks/useAppState'
import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'
import { useLogout } from '@my-drifter/hooks/useLogout'
import { useUser } from '@my-drifter/hooks/useUser'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import DropdownSelector from './DropdownSelector'
import FigmaText from './FigmaText'

type DesktopHeaderProps = {
  siteOptions?: { label: string; value: string }[]
  selectedSite?: string
  onSelectSite: (value: string) => void
}

const DesktopHeader = ({ siteOptions, selectedSite, onSelectSite }: DesktopHeaderProps) => {
  const logOut = useLogout()
  const { state } = useAppState()
  const isDesktop = useIsDesktop()
  const { data: user } = useUser(localStorage.getItem('userId') || state?.userId)

  return (
    <Container
      fullWidth
      direction="row"
      align="center"
      justify="space-between"
      zIndex={1}
      top
      left
      right
      bottom
      spacing={Spacings.s}
    >
      {!isDesktop && (
        <Box align="flex-end" onClick={logOut}>
          <FigmaText textKey={Texts.textMenuUserMenuUserMenuLogout} />
        </Box>
      )}
      {isDesktop && user?.siteIds && user?.siteIds?.length > 1 && (
        <DropdownSelector dropdownType="site" options={siteOptions} selected={selectedSite} onSelect={onSelectSite} />
      )}
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.topBorderBackground};
  padding-left: 24px;
  height: 56px;
`
// TODO: Make Header flex after adding the language selector
//   display: inline-flex;
//   justify-content: flex-end;
//   align-items: center;
// `

export default memo(DesktopHeader)
