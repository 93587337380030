import { Skeleton } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'

import formatMonth from '@pure/libs/formatMonth'

import PortalTexts from '@my-drifter/assets/texts_extended.json'
import { useLanguage } from '@my-drifter/hooks/useLanguage'

import DateTable from './DateTable'
import FigmaText from './FigmaText'

interface SessionsData {
  month: string
  date: dayjs.Dayjs
  totalSessions: number
  freeSessions: number
  paidSessions: number
  permitSessions: number
}

interface SessionsTableProps {
  data: SessionsData[]
  loading: boolean
}

function RevenueTable({ data, loading }: SessionsTableProps) {
  const localeString = useLanguage()
  const columns = React.useMemo(
    () => [
      {
        Header: <FigmaText textKey={PortalTexts.sessionStatisticssessionsBreakdownmonthColumnTitle} />,
        id: 'date',
        accessor: (row) => formatMonth(row.date, localeString),
        sortType: (rowA, rowB) => {
          const dateA = rowA.original.date
          const dateB = rowB.original.date
          return Math.floor(dateA.unix()) - Math.floor(dateB.unix())
        },
        ...(loading && { Cell: <Skeleton /> })
      },
      {
        Header: <FigmaText textKey={PortalTexts.sessionStatisticssessionsBreakdowncolumnTitleTotal} />,
        id: 'totalSessions',
        accessor: (row) => row.totalSessions?.toLocaleString(localeString),
        ...(loading && { Cell: <Skeleton /> })
      },
      {
        Header: <FigmaText textKey={PortalTexts.sessionStatisticssessionsBreakdowncolumnTitleFreePark} />,
        id: 'freeSessions',
        accessor: (row) => row.freeSessions?.toLocaleString(localeString),
        ...(loading && { Cell: <Skeleton /> })
      },
      {
        Header: <FigmaText textKey={PortalTexts.sessionStatisticssessionsBreakdowncolumnTitlePaidPark} />,
        id: 'paidSessions',
        accessor: (row) => row.paidSessions?.toLocaleString(localeString),
        ...(loading && { Cell: <Skeleton /> })
      },
      {
        Header: <FigmaText textKey={PortalTexts.sessionStatisticssessionsBreakdowncolumnTitlePermits} />,
        id: 'permitSessions',
        accessor: (row) => row.permitSessions?.toLocaleString(localeString),
        ...(loading && { Cell: <Skeleton /> })
      }
    ],
    [localeString, loading]
  )

  const tableData = React.useMemo(() => (loading ? Array(12).fill({}) : data), [loading, data])

  return <DateTable<SessionsData> columns={columns} data={tableData} />
}

export default RevenueTable
