import { collection } from 'firebase/firestore'

import Collections, { CollectionGroups } from '@contracts/enums/Collections'
import { PermitDebtor } from '@contracts/types/PermitDebtor'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export const useSitePermitDebtors = (siteId?: string) =>
  useFirestoreQuery<PermitDebtor[]>(
    collection(db, `${Collections.SITES}/${siteId}/${CollectionGroups.PERMIT_DEBTORS}`),
    {
      fnName: 'useSitePermitDebtors',
      enabled: !!siteId
    }
  )
