/**
 * @description Category of the vehicle according to EU standard. Follow 2007/46/EG for more information on categories
 * | Category Name  | Description, SE          |
 * |----------------|--------------------------|
 * | M1, M1G        | Personbil                |
 * | M2, M3         | Buss                     |
 * | M2G, M3G       | Buss, terränggående      |
 * | N1, N2, N3     | Lastbil                  |
 * | N1G, N2G, N3G  | Lastbil, terränggående   |
 * | O1, O2, O3, O4 | Släp                     |
 * | T1, T2, T3, T4 | Traktor, hjul            |
 * | C1, C2, C3, C4 | Traktor, band            |
 * | L1E            | 2-hjulig moped           |
 * | L2E            | 3-hjulig moped           |
 * | L3E            | 2-hjulig MC              |
 * | L4E            | 2-hjulig MC med sidovagn |
 * | L5E            | 3-hjulig MC              |
 * | L6E            | Lätt 4-hjuling           |
 * | L7E            | Tung 4-hjuling           |
 */

export enum VehicleEUCategory {
  M1 = 'M1',
  M1G = 'M1G',
  M2 = 'M2',
  M3 = 'M3',
  M2G = 'M2G',
  M3G = 'M3G',
  N1 = 'N1',
  N2 = 'N2',
  N3 = 'N3',
  N1G = 'N1G',
  N2G = 'N2G',
  N3G = 'N3G',
  O1 = 'O1',
  O2 = 'O2',
  O3 = 'O3',
  O4 = 'O4',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  C1 = 'C1',
  C2 = 'C2',
  C3 = 'C3',
  C4 = 'C4',
  L1E = 'L1E',
  L2E = 'L2E',
  L3E = 'L3E',
  L4E = 'L4E',
  L5E = 'L5E',
  L6E = 'L6E',
  L7E = 'L7E'
}
