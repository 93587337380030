import { FIGMA_MODES_LANG } from '@consts/FigmaConsts'
import { sv } from '@consts/Locale'

import { Variable } from '@contracts/types/Variable'

import { Language, TextKey } from '@pure/emu/Antiloop'

import { getLanguageCode } from '@my-drifter/hooks/useLanguage'

const XXX = 'XXX'
export type Printable = string | number | undefined

export type Options = {
  boldParams?: boolean
  boldParamsIndex?: number
  splitCharacter?: string
}

export const getFigmaText = (textKey: TextKey, language: Language = getLanguageCode()): string => {
  switch (language) {
    case sv:
      return textKey.texts?.swe || textKey.texts?.sv || ''
    default:
      return textKey.texts?.eng || textKey.texts?.en || ''
  }
}

export const getFigmaTextFromVariable = ({ values }: Variable, language: Language = getLanguageCode()): string =>
  values.find(({ mode }) => mode === FIGMA_MODES_LANG[language])?.value || ''
