enum InternalColors {
  topBorderBackground = '#D3DEED',
  textWhite = '#FFFFFF',
  textOffWhite = '#F0F3FA',
  navigationBlue = '#0A004A',
  navigationPressed = '#2300A3',
  purple900 = '#130068',
  infographicsBrand = '#6D63F1',
  grey600 = '#546378',
  textActionTertiary = '#5347D6',
  paginationHovered = '#C4C0FF',
  gray = '#64748B'
}

export const PortalColors = {
  topBorderBackground: InternalColors.topBorderBackground,
  textWhite: InternalColors.textOffWhite,
  textSelectedWhite: InternalColors.textWhite,
  navigationBlue: InternalColors.navigationBlue,
  navigationPressed: InternalColors.navigationPressed,
  navigationHovered: InternalColors.purple900,
  infographicsBrand: InternalColors.infographicsBrand,
  surfaceActionPrimary: InternalColors.infographicsBrand,
  surfaceActionTertiary: InternalColors.paginationHovered,
  textPrimary: InternalColors.purple900,
  textSecondary: InternalColors.grey600,
  textTertiary: InternalColors.textActionTertiary
} as const

export type PortalColors = (typeof PortalColors)[keyof typeof PortalColors]
