import React, { useEffect } from 'react'

import { onAuthStateChanged } from '@firebase/auth'

import { auth } from '@my-drifter/libs/FirebaseOptions'

interface FirebaseAuthUserContextInterface {
  authUserId: string | undefined
  setAuthUserId: (id: string) => void
}

const DEFAULT_AUTH_USER_CONTEXT = {
  authUserId: undefined,
  setAuthUserId: (id: string): void => {}
}

const AuthUserContext = React.createContext<FirebaseAuthUserContextInterface>(DEFAULT_AUTH_USER_CONTEXT)

export function FirebaseAuthUserProvider({ children }) {
  const [authUserId, setAuthUserId] = React.useState<string>()

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      firebaseUser?.getIdTokenResult().then((idTokenResult) => {
        setAuthUserId(String(idTokenResult.claims['lbeUserId']))
      })
    })
  }, [auth.currentUser])

  return <AuthUserContext.Provider value={{ authUserId, setAuthUserId }}>{children}</AuthUserContext.Provider>
}

export function useAuthUserContext() {
  const context = React.useContext(AuthUserContext)
  if (!context) {
    throw new Error('useAuthUserContext must be used within a FirebaseAuthUserProvider')
  }
  return context
}
