import ImportGatan7983Svg from './importgatan_7983.svg'
export const ImportGatan7983 = ImportGatan7983Svg
import SevenNineEightEightSvg from './7988.svg'
export const SevenNineEightEight = SevenNineEightEightSvg
import KungalvBostader7989Svg from './kungalvbostader.svg'
export const KungalvBostader7989 = KungalvBostader7989Svg
import SevenNineNineZeroSvg from './7990.svg'
export const SevenNineNineZero = SevenNineNineZeroSvg
import SevenNineNineOneSvg from './7991.svg'
export const SevenNineNineOne = SevenNineNineOneSvg
import SevenNineNineTwoSvg from './7992.svg'
export const SevenNineNineTwo = SevenNineNineTwoSvg
import SevenNineNineFiveSvg from './7995.svg'
export const SevenNineNineFive = SevenNineNineFiveSvg
import SevenNineNineEightSvg from './7998.svg'
export const SevenNineNineEight = SevenNineNineEightSvg
import Raberget7999Svg from './raberget.svg'
export const Raberget7999 = Raberget7999Svg
import EightThousandSvg from './8000.svg'
export const EightThousand = EightThousandSvg
import Honekullavagen8001Svg from './honekullavagen.svg'
export const Honekullavagen8001 = Honekullavagen8001Svg
import NivikaJkpg8003Svg from './nivika_jonkoping.svg'
export const NivikaJkpg8003 = NivikaJkpg8003Svg
import EightThousandFourSvg from './8004.svg'
export const EightThousandFour = EightThousandFourSvg
import Redegatan8006Svg from './redegatan.svg'
export const Redegatan8006 = Redegatan8006Svg
import Bergshamra8007Svg from './bergshamra.svg'
export const Bergshamra8007 = Bergshamra8007Svg
