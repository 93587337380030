import { TextKey } from '@pure/emu/Antiloop'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

export function HomeErrorMessage({ textKey, text }: { textKey: TextKey; text?: string }) {
  return (
    <Box direction="row" fullWidth>
      <Box left spacing={Spacings.xxs}>
        <FigmaText textKey={textKey} text={text} />
      </Box>
    </Box>
  )
}
