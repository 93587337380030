import { collection, collectionGroup, doc, getDocs, setDoc } from 'firebase/firestore'

import Collections, { CollectionGroups } from '@contracts/enums/Collections'
import { HikerUser } from '@contracts/types/HikerUser'
import { Permit } from '@contracts/types/Permit'

import { db } from './FirebaseOptions'

export const upsertPermit = async (
  siteId: string,
  permitDebtorId: string,
  permitId: string,
  updatedPermit: Permit
): Promise<Permit> =>
  setDoc(
    doc(
      db,
      `${Collections.SITES}/${siteId}/${CollectionGroups.PERMIT_DEBTORS}/${permitDebtorId}/${CollectionGroups.PERMITS}/${permitId}`
    ),
    updatedPermit
  ).then(() => updatedPermit)

export const getUserByPhoneNumber = async (phoneNumber?: string | null) => {
  const userQuery = await getDocs(collection(db, Collections.USERS))
  const users = userQuery.docs.map((doc) => doc.data() as HikerUser)

  return users.find((user) => user.phone === phoneNumber)
}

export const getPermitById = async (permitId: string) => {
  const permitQuery = await getDocs(collectionGroup(db, CollectionGroups.PERMITS))
  const permits = permitQuery.docs.map((doc) => doc.data() as Permit)

  return permits.find((permit) => permit.id === permitId)
}
