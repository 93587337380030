import { doc } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { HikerUser } from '@contracts/types/HikerUser'

import { createUniqueId } from '@pure/libs/Common'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export const useUser = (userId?: string | null) =>
  useFirestoreQuery<HikerUser>(doc(db, `${Collections.USERS}/${userId || createUniqueId()}`), {
    fnName: 'useUser',
    enabled: !!userId
  })
