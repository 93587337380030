import styled from '@emotion/styled'
import ControlPoint from '@mui/icons-material/ControlPoint'
import dayjs from 'dayjs'
import { memo, useContext, useState } from 'react'

import { Permit, UserPermitConnection } from '@contracts/types/Permit'

import Texts from '@my-drifter/libs/Texts'
import { PermitsContext } from '@my-drifter/store/PermitsProvider'

import { Spacings } from '@web-components/enums/Spacings'

import AddUserDrawer from './AddUserDrawer'
import Box from './Box'
import FigmaText from './FigmaText'
import PermitsUserBox from './PermitsUserBox'
import UpdateUserDrawer from './UpdateUserDrawer'

type PermitsConnectedUsersProps = {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
}

const PermitsConnectedUsers = ({ permit, selectedSiteId, selectedSitePermitDebtorId }: PermitsConnectedUsersProps) => {
  const {
    isDesktop,
    isAnyDrawerOpen,
    isUserDrawerOpen,
    setIsUserDrawerOpen,
    isUpdateUserDrawerOpen,
    setIsUpdateUserDrawerOpen
  } = useContext(PermitsContext)
  const [userPermitConnectionToDelete, setUserPermitConnectionToDelete] = useState<UserPermitConnection | undefined>()
  return (
    <>
      <Container
        fullWidth
        align="center"
        bottom
        spacing={Spacings.xxxl}
        isDrawerOpen={isAnyDrawerOpen}
        isDesktop={isDesktop}
      >
        <Box fullWidth direction="row" align="center" justify="space-between" top spacing={Spacings.xxxl}>
          <FigmaText textKey={Texts.textMyPortalConnectedUsersHeader} />
          <AddUserButton onClick={() => setIsUserDrawerOpen(!isUserDrawerOpen)}>
            <ControlPoint fontSize="medium" />
          </AddUserButton>
        </Box>
        <Box fullWidth top>
          {permit?.userPermitConnections?.map((userPermitConnection) => {
            if (userPermitConnection.expiryDate && dayjs(userPermitConnection.expiryDate).isBefore(dayjs())) return
            return (
              <PermitsUserBox
                key={userPermitConnection?.userId}
                userPermitConnection={userPermitConnection}
                setIsDeleteUserDrawerOpen={setIsUpdateUserDrawerOpen}
                setUserPermitConnectionToDelete={setUserPermitConnectionToDelete}
              />
            )
          })}
        </Box>
      </Container>
      <AddUserDrawer
        isDrawerOpen={isUserDrawerOpen}
        setIsDrawerOpen={setIsUserDrawerOpen}
        selectedSiteId={selectedSiteId}
        selectedSitePermitDebtorId={selectedSitePermitDebtorId}
        permit={permit}
      />
      <UpdateUserDrawer
        isDrawerOpen={isUpdateUserDrawerOpen}
        setIsDrawerOpen={setIsUpdateUserDrawerOpen}
        permit={permit}
        selectedSiteId={selectedSiteId}
        selectedSitePermitDebtorId={selectedSitePermitDebtorId}
        userPermitConnection={userPermitConnectionToDelete}
      />
    </>
  )
}

const Container = styled(Box)<{ isDrawerOpen?: boolean; isDesktop: boolean }>`
  max-width: ${(props) => (props.isDrawerOpen ? '46%' : props.isDesktop ? '520px' : '100%')};
  transition: max-width 0.2s ease-in-out;
`

const AddUserButton = styled(Box)`
  cursor: pointer;
`

export default memo(PermitsConnectedUsers)
