import { memo } from 'react'

import Box from '@my-drifter/components/Box'
import FigmaText from '@my-drifter/components/FigmaText'
import Layout from '@my-drifter/components/Layout'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

const NotAvailableOnMobileScreen = () => {
  return (
    <Layout>
      <Box top spacing={Spacings.xxxl}>
        <FigmaText textKey={Texts.textMyPortalMobileViewNotAvailable} />
      </Box>
    </Layout>
  )
}

export default memo(NotAvailableOnMobileScreen)
