// @flow
import { createTheme } from '@mui/material'

import { PortalColors } from '@my-drifter/components/PortalColors'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

export const DefaultMaterialTheme = {
  typography: {
    fontFamily: 'Poppins'
  },
  palette: {
    primary: createColor(PortalColors.textPrimary),
    secondary: createColor(PortalColors.textSecondary)
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#000'
      }
    }
  }
}

export const MaterialTheme = createTheme(DefaultMaterialTheme)
