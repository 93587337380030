import styled from '@emotion/styled'
import { memo, useContext } from 'react'

import Box from '@my-drifter/components/Box'
import DropdownSelector from '@my-drifter/components/DropdownSelector'
import FigmaText from '@my-drifter/components/FigmaText'
import FullScreenSpinner from '@my-drifter/components/FullScreenSpinner'
import Layout from '@my-drifter/components/Layout'
import PermitsConnectedUsers from '@my-drifter/components/PermitsConnectedUsers'
import PermitsConnectedVehicles from '@my-drifter/components/PermitsConnectedVehicles'
import PermitsStaffMembers from '@my-drifter/components/PermitsStaffMembers'
import Texts from '@my-drifter/libs/Texts'
import { PermitsContext } from '@my-drifter/store/PermitsProvider'

import { Spacings } from '@web-components/enums/Spacings'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const PermitsScreen = () => {
  const {
    isAllowed,
    isLoadingUser,
    isDesktop,
    selectedSiteId,
    setSelectedSiteId,
    selectedSitePermitDebtorId,
    setSelectedSitePermitDebtorId,
    selectedSitePermitDebtorPermitId,
    setSelectedSitePermitDebtorPermitId,
    selectedPermit,
    sitePermitDebtorOptions,
    sitePermitDebtorPermitOptions,
    isAnyDrawerOpen
  } = useContext(PermitsContext)

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  return !isLoadingUser ? (
    <Layout selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId} isDrawerOpen={isAnyDrawerOpen}>
      <Box fullWidth fullHeight>
        {!selectedSiteId && (
          <CenteredText>
            <FigmaText textKey={Texts.textMyPortalPermitEmptyStateNoSiteSelected} />
          </CenteredText>
        )}
        <Box fullWidth align={isDesktop ? 'center' : undefined} top spacing={Spacings.xxxl}>
          {selectedSiteId && (
            <DropdownSelector
              dropdownType="siteDebtor"
              options={sitePermitDebtorOptions}
              selected={selectedSitePermitDebtorId}
              onSelect={(value) => setSelectedSitePermitDebtorId(value)}
            />
          )}
          {selectedSiteId && selectedSitePermitDebtorId && (
            <DropdownSelector
              dropdownType="permit"
              options={sitePermitDebtorPermitOptions}
              selected={selectedSitePermitDebtorPermitId}
              onSelect={(value) => setSelectedSitePermitDebtorPermitId(value)}
            />
          )}
          {selectedSiteId && selectedSitePermitDebtorId && selectedSitePermitDebtorPermitId && (
            <PermitsConnectedVehicles
              selectedSiteId={selectedSiteId}
              selectedSitePermitDebtorId={selectedSitePermitDebtorId}
              permit={selectedPermit}
            />
          )}
          {selectedSiteId && selectedSitePermitDebtorId && selectedSitePermitDebtorPermitId && (
            <PermitsConnectedUsers
              selectedSiteId={selectedSiteId}
              selectedSitePermitDebtorId={selectedSitePermitDebtorId}
              permit={selectedPermit}
            />
          )}
          {selectedSiteId &&
            selectedSitePermitDebtorId &&
            selectedSitePermitDebtorPermitId &&
            selectedPermit?.type === 'guest' && (
              <PermitsStaffMembers
                selectedSiteId={selectedSiteId}
                selectedSitePermitDebtorId={selectedSitePermitDebtorId}
                permit={selectedPermit}
              />
            )}
        </Box>
      </Box>
    </Layout>
  ) : (
    <FullScreenSpinner />
  )
}

const CenteredText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 80vh;
`

export default memo(PermitsScreen)
