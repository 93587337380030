import styled from '@emotion/styled'

import ZIndices from '@web-js/enums/Zindices'

import { Spacings } from '@web-components/enums/Spacings'

import { PortalSpacings } from './PortalSpacings'
import { LinkStyles } from './StyledComponents'

export type BoxProps = {
  left?: boolean
  right?: boolean
  top?: boolean
  bottom?: boolean
  spacing?: Spacings | PortalSpacings
  fullWidth?: boolean
  fullHeight?: boolean
  fullPadding?: boolean
  link?: boolean
  alignSelf?: 'center' | 'flex-start' | 'flex-end' | 'normal' | 'stretch'
  align?: 'center' | 'flex-start' | 'flex-end' | 'normal' | 'stretch'
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-around' | 'space-between' | 'space-evenly'
  alignText?: 'center' | 'left' | 'right'
  direction?: 'row' | 'column'
  flexGrow?: number
  multiline?: boolean
  width?: string
  position?: 'relative' | 'absolute' | 'fixed'
  zIndex?: ZIndices
  visibility?: 'visible' | 'hidden'
  minWidth?: 'auto' | '0'
  pointer?: boolean
  showBorder?: boolean
  backgroundColor?: string
  boxShadow?: string
  gap?: Spacings | PortalSpacings
  disabled?: boolean
}

const Box = styled.div<BoxProps>`
  display: flex;
  box-sizing: border-box;
  flex-direction: ${({ direction }) => direction || `column`};
  text-align: ${({ alignText }) => !!alignText && `${alignText}`};
  justify-content: ${({ justify }) => !!justify && `${justify}`};
  align-items: ${({ align }) => !!align && `${align}`};
  padding-left: ${({ left, spacing, fullPadding }) => (left || fullPadding ? spacing || PortalSpacings.x3 : 0)};
  padding-right: ${({ right, spacing, fullPadding }) => (right || fullPadding ? spacing || PortalSpacings.x3 : 0)};
  padding-top: ${({ top, spacing, fullPadding }) => (top || fullPadding ? spacing || PortalSpacings.x3 : 0)};
  padding-bottom: ${({ bottom, spacing, fullPadding }) => (bottom || fullPadding ? spacing || PortalSpacings.x3 : 0)};
  gap: ${({ gap }) => gap || 0};
  width: ${({ fullWidth, width }) => width || (fullWidth ? '100%' : 'fit-content')};
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  z-index: ${({ zIndex }) => zIndex || ZIndices.regular};
  min-width: ${({ minWidth }) => minWidth || '0'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  box-shadow: ${({ boxShadow }) => boxShadow || 'none'};
  ${({ showBorder }) => (showBorder ? 'border: 1px solid red;' : '')};
  ${({ link }) => (link ? LinkStyles : '')}
  ${({ pointer }) => (pointer ? 'cursor: pointer;' : '')}
    ${({ multiline }) => (multiline ? `word-break: break-word;` : '')}
    ${({ position }) => (position ? `position: ${position};` : '')}
    ${({ alignSelf }) => (alignSelf ? `align-self: ${alignSelf};` : '')}
    ${({ visibility }) => (visibility ? `visibility: ${visibility};` : '')};
  ${({ disabled }) => (disabled ? 'pointer-events: none; opacity: 0.5;' : '')}
`
export default Box
