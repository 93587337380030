import { doc } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { Site } from '@contracts/types/Site'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export const useSite = (siteId?: string) =>
  useFirestoreQuery<Site>(doc(db, `${Collections.SITES}/${siteId}`), {
    fnName: 'useSite',
    enabled: !!siteId
  })
