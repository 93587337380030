import styled from '@emotion/styled'
import { Button as MuiButton } from '@mui/material'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { RequiredTextValidation } from '@web-js/libs/ValidationHelper'

import { useForm } from '@my-drifter/hooks/useForm'
import Images from '@my-drifter/libs/Images'
import { getFigmaTextFromVariable } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'
import { Variables } from '@my-drifter/libs/Variables'
import { SignInViewProps } from '@my-drifter/types/SignInTypes'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import Button from './Button'
import FigmaImage from './FigmaImage'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import TextFieldForm from './TextFieldForm'

const LoginEnterCode = (props: SignInViewProps) => {
  const validationSchema = Yup.object().shape({
    code: RequiredTextValidation('Enter SMS code')
  })

  const formProps = useForm(validationSchema, { defaultValues: { code: '' } })

  const _onPressContinue = (values: string) => {
    return props.onPressContinue({ ...props.signInState, data: { ...props.signInState.data, code: values } })
  }

  const onSubmit = () => {
    if (!formProps.getValues('code')) return
    _onPressContinue(formProps.getValues('code'))
  }

  return (
    <Box fullWidth fullHeight align="center" top spacing={Spacings.xxxl}>
      <FigmaImageContainer imageKey={Images.drifterLogoDark} width={300} />
      <StyledBox direction="row" align="center" justify="space-between" top left right bottom spacing={Spacings.xs}>
        <FigmaImage imageKey={Images.checkIconOutlined} />
        <TextContainer left right spacing={Spacings.xs}>
          <FigmaText textKey={Texts.textNotificationsNotificationGuestParkingSentCode} />
        </TextContainer>
        <MuiButton autoCapitalize="false" onClick={props.onPressBack}>
          {'Back'}
        </MuiButton>
      </StyledBox>
      <Container fullWidth top>
        <FigmaText
          textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
          text={getFigmaTextFromVariable(Variables['VariableID:1274:12272'])}
        />
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box fullWidth top spacing={Spacings.s}>
            <Controller
              control={formProps.control}
              name="code"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  value={field.value.toLocaleUpperCase()}
                  fullWidth
                  fieldState={fieldState}
                  autoFocus
                  placeholder="Code"
                />
              )}
            />
          </Box>
          <Box fullWidth top spacing={Spacings.xxl}>
            <Button
              fullWidth
              text={getFigmaTextFromVariable(Variables['VariableID:362:6744'])}
              type="submit"
              loading={props.isLoading}
            />
          </Box>
        </form>
      </Container>
    </Box>
  )
}

const StyledBox = styled(Box)`
  max-width: 400px;
  border: 2px solid green;
  border-radius: 10px;
  margin-top: ${Spacings.s};
`

const TextContainer = styled(Box)`
  max-width: 260px;
`

const Container = styled(Box)`
  max-width: 350px;
`

export default LoginEnterCode
