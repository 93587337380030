import { collection } from 'firebase/firestore'

import Collections, { CollectionGroups } from '@contracts/enums/Collections'
import { Permit } from '@contracts/types/Permit'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export const useSitePermitDebtorsPermits = (siteId?: string, permitDebtorId?: string) =>
  useFirestoreQuery<Permit[]>(
    collection(
      db,
      `${Collections.SITES}/${siteId}/${CollectionGroups.PERMIT_DEBTORS}/${permitDebtorId}/${CollectionGroups.PERMITS}`
    ),
    {
      fnName: 'useSitePermitDebtorsPermits',
      enabled: !!siteId && !!permitDebtorId
    }
  )
