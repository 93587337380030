import { collection } from 'firebase/firestore'

import Collections, { CollectionGroups } from '@contracts/enums/Collections'
import { Site } from '@contracts/types/Site'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export const useGetActiveSiteCameraEvents = (siteId?: string) =>
  useFirestoreQuery<Site>(
    collection(db, `${Collections.SITES}/${siteId}/${CollectionGroups.ACTIVE_SITE_CAMERA_EVENTS}`),
    {
      fnName: 'useGetActiveSiteCameraEvents',
      enabled: !!siteId
    }
  )
