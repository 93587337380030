import styled from '@emotion/styled'
import { Construction as ConstructionIconFilled, ConstructionOutlined } from '@mui/icons-material'
import { memo } from 'react'
import { Link } from 'react-router-dom'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { ImageKey, TextKey } from '@pure/emu/Antiloop'

import ZIndices from '@web-js/enums/Zindices'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import PortalTexts from '@my-drifter/assets/texts_extended.json'
import useAppState from '@my-drifter/hooks/useAppState'
import { useIsXLDesktop } from '@my-drifter/hooks/useIsMobile'
import { useLogout } from '@my-drifter/hooks/useLogout'
import { useUser } from '@my-drifter/hooks/useUser'
import Images from '@my-drifter/libs/Images'

import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { LAYOUT_LEFT_PADDING, LAYOUT_LEFT_XL_PADDING } from './HardcodedSizes'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

interface NavigationItem {
  icon: ImageKey | (() => React.ReactNode)
  iconFilled: ImageKey | (() => React.ReactNode)
  textKey: TextKey
  path: string
}

interface RenderNavigationItemsProps {
  navigationItems: NavigationItem[]
  url: string
}

const RenderNavigationItems = ({ navigationItems, url }: RenderNavigationItemsProps) =>
  navigationItems.map(({ textKey, path, icon: icon, iconFilled }) => (
    <StyledNavigationLink key={textKey.name} to={path} isActive={path === url}>
      {typeof icon === 'function' ? icon() : <FigmaImage imageKey={icon} />}
      <FigmaText textKey={textKey} />
    </StyledNavigationLink>
  ))

const LeftNavigation = () => {
  const isXLDesktop = useIsXLDesktop()
  const logOut = useLogout()
  const url = window.location.pathname
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const dashboardItems = [
    {
      icon: Images.dashboard,
      iconFilled: Images.dashboardFilled,
      textKey: PortalTexts.navigationDrawerdashboard,
      path: '/dashboard'
    }
  ]

  const mgItems =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user.siteIds?.length !== 0
      ? [
          // { icon: Images.map, textKey: PortalTexts.navigationDrawerdashboard, path: '/dashboard' },
          {
            icon: Images.permit,
            iconFilled: Images.permitFilled,
            textKey: PortalTexts.navigationDrawerpermitsManagement,
            path: '/permits'
          }
        ]
      : [
          {
            icon: Images.permit,
            iconFilled: Images.permitFilled,
            textKey: PortalTexts.navigationDrawerpermitsManagement,
            path: '/permits'
          }
        ]

  const insightItems =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds?.length !== 0
      ? [
          {
            icon: Images.carIcon,
            iconFilled: Images.carIconFilled,
            textKey: PortalTexts.navigationDrawersessionStatistics,
            path: '/sessions'
          },
          {
            icon: Images.payments,
            iconFilled: Images.paymentsFilled,
            textKey: PortalTexts.navigationDrawerrevenueReport,
            path: '/revenue'
          }
        ]
      : []

  const internalItems =
    user?.role === HikerUserRole.ROOT
      ? [
          {
            icon: () => <ConstructionOutlined />,
            iconFilled: () => <ConstructionIconFilled />,
            textKey: TextsExtended.staticQRMakerMenuItem,
            path: '/static-qr-maker'
          }
        ]
      : []

  return (
    <StyledNavigation isXLDesktop={isXLDesktop}>
      <TopNavContainer>
        <TopIconContainer>
          <FigmaImage imageKey={Images.drifterLogoLight} imageStyle={logoStyle} />
        </TopIconContainer>
        <RenderNavigationItems navigationItems={dashboardItems} url={url} />
        <Divider />
        <SectionTitleContainer>
          <SectionTitleWrapper>
            <FigmaText textKey={PortalTexts.navigationDrawertitleManagement} />
          </SectionTitleWrapper>
          <RenderNavigationItems navigationItems={mgItems} url={url} />
          <Divider />
        </SectionTitleContainer>
        <SectionTitleContainer>
          <SectionTitleWrapper>
            <FigmaText textKey={PortalTexts.navigationDrawertitleInsights} />
          </SectionTitleWrapper>
          <RenderNavigationItems navigationItems={insightItems} url={url} />
        </SectionTitleContainer>
        {internalItems.length > 0 && (
          <>
            <Divider />
            <SectionTitleContainer>
              <SectionTitleWrapper>
                <FigmaText textKey={TextsExtended.internalToolsMenuTitle} />
              </SectionTitleWrapper>
              <RenderNavigationItems navigationItems={internalItems} url={url} />
            </SectionTitleContainer>
          </>
        )}
      </TopNavContainer>
      <BottomNavContainer>
        <StyledLogoutButton onClick={logOut}>
          <FigmaImage imageKey={Images.logout} />
          <FigmaText textKey={PortalTexts.navigationDrawerlogout} />
        </StyledLogoutButton>
        <CopyRightContainer>
          <FigmaText textKey={PortalTexts.navigationDrawerallRightsReserved} />
        </CopyRightContainer>
      </BottomNavContainer>
    </StyledNavigation>
  )
}

const dividerStyle = {
  width: '100%',
  strokeWidth: '1px',
  stroke: `${PortalColors.infographicsBrand}`
}

const Divider = () => <FigmaImage imageKey={Images.divider} imageStyle={dividerStyle} />

const TopNavContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const BottomNavContainer = styled.div`
  flex: none;
  gap: ${PortalSpacings.x2};
  padding: ${PortalSpacings.x3} 0px;
  flex-direction: column;
`

const logoStyle = {
  padding: `${PortalSpacings.x2} ${PortalSpacings.x1}`
}

const TopIconContainer = styled.div`
  padding: ${PortalSpacings.x4} ${PortalSpacings.x1};
`

const CopyRightContainer = styled.div`
  display: flex;
  padding: ${PortalSpacings.x4} ${PortalSpacings.x3};
  align-items: center;
  align-self: stretch;
`

const SectionTitleContainer = styled.div`
  flex-direction: column;
  padding: 0;
  align-items: flex-start;
`

const StyledNavigation = styled(({ isXLDesktop: boolean, ...props }) => <nav {...props} />)`
  width: ${({ isXLDesktop }) => (isXLDesktop ? LAYOUT_LEFT_XL_PADDING : LAYOUT_LEFT_PADDING)};
  height: 100vh;
  padding: 0px ${PortalSpacings.x3};
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  background-color: ${PortalColors.navigationBlue};
  z-index: ${ZIndices.heaven};
  transition: all 0.2s ease-in-out;
`

const StyledNavigationLink = styled(({ isActive: boolean, ...props }) => <Link to={props.to} {...props} />)`
  display: flex;
  align-self: stretch;
  padding: ${PortalSpacings.x3};
  cursor: pointer;
  align-items: center;
  gap: ${PortalSpacings.x3};
  text-decoration: none;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  border: 2px solid transparent;
  border-radius: ${PortalSpacings.x2};
  color: ${({ isActive }) => (isActive ? PortalColors.textSelectedWhite : PortalColors.textWhite)};
  background-color: ${({ isActive }) => (isActive ? PortalColors.navigationPressed : 'transparent')};

  :hover {
    background-color: ${PortalColors.navigationHovered};
    border-radius: ${PortalSpacings.x2};
  }
`

const SectionTitleWrapper = styled.div`
  text-transform: uppercase;
  padding: ${PortalSpacings.x3} ${PortalSpacings.x4} ${PortalSpacings.x3} ${PortalSpacings.x3};
`

const StyledLogoutButton = styled.div`
  display: flex;
  padding: ${PortalSpacings.x3};
  gap: ${PortalSpacings.x3};
  align-self: stretch;

  cursor: pointer;

  &:hover {
    background-color: ${PortalColors.navigationHovered};
    border-radius: ${PortalSpacings.x2};
  }
`

export default memo(LeftNavigation)
