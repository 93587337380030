import styled from '@emotion/styled'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { memo, ReactNode } from 'react'

import { Colors } from '@pure/assets/Colors'

import ZIndices from '@web-js/enums/Zindices'

import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'

type RightSideDrawerProps = {
  isDrawerOpen: boolean
  setIsDrawerOpen: (value: boolean) => void
  children: ReactNode
}

const RightSideDrawer = ({ isDrawerOpen, setIsDrawerOpen, children }: RightSideDrawerProps) => {
  const isDesktop = useIsDesktop()

  return (
    <StyledDrawer position="fixed" zIndex={ZIndices.modal} isDrawerOpen={isDrawerOpen} isDesktop={isDesktop}>
      <BackButton top left bottom spacing={Spacings.xs} onClick={() => setIsDrawerOpen(false)}>
        <BackButtonIcon fontSize="large" />
      </BackButton>
      <Box fullWidth left right bottom spacing={Spacings.s}>
        {children}
      </Box>
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Box)<{ isDrawerOpen: boolean; isDesktop: boolean }>`
  top: 0;
  right: 0;
  width: ${(props) => (props.isDesktop ? '350px' : '100%')};
  height: 100vh;
  z-index: ${ZIndices.modal};
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
  ${(props) => props.isDrawerOpen && `transform: translateX(0);`};
  background-color: ${Colors.white};
`

const BackButton = styled(Box)`
  cursor: pointer;
`

const BackButtonIcon = styled(KeyboardBackspaceIcon)`
  color: ${Colors.brandPink};
`

export default memo(RightSideDrawer)
