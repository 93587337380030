import { User } from 'firebase/auth'
import React, { useContext } from 'react'

import { StorageKey } from './useLocalStorageItemHelper'
import { useStorageItem } from './useStorageItem'

export type State = {
  userId?: string
  firebaseUser?: User
}

export const DEFAULT_STATE: State = {
  userId: undefined,
  firebaseUser: undefined
}

export type AppContext = {
  state: State
  setState: (state: State) => unknown
}
export const Context = React.createContext<AppContext>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

const IGNORED_KEYS: Array<keyof State> = []

export const useAppStateContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    ignoredKeys: IGNORED_KEYS
  })

  return { state, setState }
}

export default function useAppState() {
  return useContext(Context)
}
