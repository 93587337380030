import styled from '@emotion/styled'
import ControlPoint from '@mui/icons-material/ControlPoint'
import { memo, useContext } from 'react'

import { Permit } from '@contracts/types/Permit'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import AddStaffMemberDrawer from '@my-drifter/components/AddStaffMemberDrawer'
import PermitsStaffMemberBox from '@my-drifter/components/PermitsStaffMemberBox'
import { PermitsContext } from '@my-drifter/store/PermitsProvider'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

type PermitsConnectedUsersProps = {
  permit?: Permit
  selectedSiteId: string
  selectedSitePermitDebtorId: string
}

const PermitsStaffMembers = ({ permit, selectedSiteId, selectedSitePermitDebtorId }: PermitsConnectedUsersProps) => {
  const { isDesktop, isUpdateStaffMembersDrawerOpen, setIsUpdateStaffMembersDrawerOpen, isAnyDrawerOpen } =
    useContext(PermitsContext)

  return (
    <>
      <Container
        fullWidth
        align="center"
        bottom
        spacing={Spacings.xxxl}
        isDrawerOpen={isAnyDrawerOpen}
        isDesktop={isDesktop}
      >
        <Box fullWidth direction="row" align="center" justify="space-between" top spacing={Spacings.xxxl}>
          <FigmaText textKey={TextsExtended.textMyPortalStaffMembersHeader} />
          <AddStaffMemberButton onClick={() => setIsUpdateStaffMembersDrawerOpen(!isUpdateStaffMembersDrawerOpen)}>
            <ControlPoint fontSize="medium" />
          </AddStaffMemberButton>
        </Box>
        <Box fullWidth top>
          {permit?.staffMembersIds?.map((userId) => (
            <PermitsStaffMemberBox
              permit={permit}
              selectedSiteId={selectedSiteId}
              selectedSitePermitDebtorId={selectedSitePermitDebtorId}
              key={`member-box-${userId}`}
              userId={userId.toString()}
            />
          ))}
        </Box>
      </Container>
      <AddStaffMemberDrawer
        isDrawerOpen={isUpdateStaffMembersDrawerOpen}
        setIsDrawerOpen={setIsUpdateStaffMembersDrawerOpen}
        selectedSiteId={selectedSiteId}
        selectedSitePermitDebtorId={selectedSitePermitDebtorId}
        permit={permit}
      />
      <StyledModalRoot id="modal-root" />
    </>
  )
}

const StyledModalRoot = styled.div`
  top: 0;
  left: 0;
  position: fixed;
`

const Container = styled(Box)<{ isDrawerOpen?: boolean; isDesktop: boolean }>`
  max-width: ${(props) => (props.isDrawerOpen ? '46%' : props.isDesktop ? '520px' : '100%')};
  transition: max-width 0.2s ease-in-out;
`

const AddStaffMemberButton = styled(Box)`
  cursor: pointer;
`

export default memo(PermitsStaffMembers)
