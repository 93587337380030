// Modal.tsx

import styled from '@emotion/styled'
import React, { memo, useState } from 'react'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'
import { TextKey } from '@pure/emu/Antiloop'

import ZIndices from '@web-js/enums/Zindices'

import { useIsDesktop } from '@my-drifter/hooks/useIsMobile'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import FigmaText from './FigmaText'

interface FullScreenModalProps {
  isOpen: boolean
  titleTextKey: TextKey
  leftButtonTextKey: TextKey
  leftButtonOnClick: () => void
  rightButtonTextKey: TextKey
  rightButtonOnClick: () => void
}

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  isOpen,
  titleTextKey = Texts.textNotificationsNotificationVerifyRemovalOfUserFromPermit,
  leftButtonTextKey = Texts.textButtonsCtaVerifyAndRemoveUser,
  leftButtonOnClick,
  rightButtonTextKey = Texts.textButtonsManageCancel,
  rightButtonOnClick
}) => {
  const isDesktop = useIsDesktop()

  const [isLeftLoading, setIsLeftLoading] = useState(false)
  const [isRightLoading, setIsRightLoading] = useState(false)

  const handleLeftButtonClick = async () => {
    setIsLeftLoading(true)
    try {
      await leftButtonOnClick()
    } finally {
      setIsLeftLoading(false)
    }
  }

  const handleRightButtonClick = async () => {
    setIsRightLoading(true)
    try {
      await rightButtonOnClick()
    } finally {
      setIsRightLoading(false)
    }
  }

  if (!isOpen) return null

  return (
    <ModalOverlay
      fullWidth
      position="fixed"
      align="center"
      justify="center"
      zIndex={ZIndices.modal}
      isDesktop={isDesktop}
    >
      <ModalContent fullWidth fullPadding spacing={Spacings.l} align="center" isDesktop={isDesktop}>
        <Box fullWidth align="flex-start" bottom>
          <FigmaText textKey={titleTextKey} />
        </Box>
        <Box fullWidth direction="row" align="center" justify="flex-start" gap={Spacings.m}>
          <ModalButton
            fullWidth
            fullPadding
            spacing={Spacings.s}
            backgroundColor={Colors.red7}
            onClick={handleLeftButtonClick}
            disabled={isLeftLoading}
          >
            <FigmaText textKey={leftButtonTextKey} />
          </ModalButton>
          <ModalButton
            fullWidth
            fullPadding
            spacing={Spacings.s}
            backgroundColor={Colors.gray200}
            onClick={handleRightButtonClick}
            disabled={isRightLoading}
          >
            <FigmaText textKey={rightButtonTextKey} />
          </ModalButton>
        </Box>
      </ModalContent>
    </ModalOverlay>
  )
}

const ModalOverlay = styled(Box)<{ isDesktop: boolean }>`
  top: 0;
  left: ${(props) => (!props.isDesktop ? 0 : undefined)};
  bottom: 0;
`

const ModalButton = styled(Box)<{ backgroundColor: string }>`
  cursor: pointer;
  border-radius: ${BorderRadiusesPx.rounded};
  background-color: ${(props) => props.backgroundColor};

  :hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  }
`

const ModalContent = styled(Box)<{ isDesktop: boolean }>`
  background-color: white;
  border: 2px solid ${Colors.primary};
  border-radius: ${BorderRadiusesPx.minimum};
  max-width: ${(props) => (props.isDesktop ? '26%' : '90%')};
`

export default memo(FullScreenModal)
